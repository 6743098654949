import AbstractRestApi from '@/api/RestApi/AbstractRestApi'

class MediaApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/media'
    }
    upload(file) {
        let formData = new FormData()
        formData.append('file', file)

        return this.create(formData, {
            'Content-Type': 'multipart/form-data',
        })
    }
}

export default new MediaApiClient()
