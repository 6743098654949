<script>
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'
import MediaApiClient from '@/api/RestApi/MediaApiClient'
import Notification from '@/services/Notification/Notification'
import isEmpty from 'lodash/isEmpty'

export default {
    mixins: [ErrorMsgInputMixin],
    props: {
        card: Boolean, // card format
        multiple: Boolean, // multiples images
        onlyDrag: Boolean, //only drag and drop
        onlyInput: Boolean, // only input field
        noUpload: Boolean, // no upload, emits the file
        loading: Boolean,
        disabled: Boolean,
        hideActions: Boolean,
        value: {
            type: [Object, Array, File],
        },
    },
    data() {
        return {
            dragged: false,
            loadingInner: false,
            uploadedFiles: [],
            requestErrors: [],
            file: null,
            image: null,
            dragHeight: 185,
            uploadElevation: 2,
        }
    },
    computed: {
        errorCollection() {
            return [...this.errorMessages, ...this.requestErrors]
        },
        showDrag() {
            return !this.onlyInput
        },
        showInput() {
            return !this.onlyDrag
        },
        fileIsEmpty() {
            return this.multiple ? isEmpty(this.file) : !this.file
        },
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(value) {
                if (
                    (this.multiple ? !value?.length : !value) &&
                    !this.fileIsEmpty
                ) {
                    this.file = null
                }
            },
        },
    },
    mounted() {
        if (this.multiple) this.file = []
    },
    methods: {
        isEmpty: () => isEmpty(),
        onDrop(e) {
            if (this.showDrag && !this.disabled) {
                this.dragged = false
                if (!this.file || this.file.length > 0)
                    this.file = this.multiple ? [] : null
                if (!this.multiple && e.dataTransfer.files.length > 1) {
                    Notification.warning(
                        'Only one file can be uploaded at a time.'
                    )
                } else {
                    if (this.multiple) {
                        e.dataTransfer.files.forEach((element) =>
                            this.file.push(element)
                        )
                    } else {
                        this.file = e.dataTransfer.files[0]
                    }
                    this.submit()
                }
            }
        },
        openUploaderDialog() {
            if (!this.loadingInner && !this.disabled)
                this.$refs.upload.$refs.input.click()
        },
        submit() {
            if (this.noUpload) this.$emit('input', this.file)
        },
        uploadImages() {
            if (!this.fileIsEmpty) {
                this.loadingInner = true
                this.requestErrors = []
                if (this.multiple) {
                    Promise.all(this.file.map((file) => this.upload(file)))
                        .then((media) => {
                            this.$emit('input', media)
                        })
                        .finally(() => {
                            this.loadingInner = false
                        })
                } else {
                    this.upload(this.file)
                        .then((media) => {
                            this.$emit('input', media)
                        })
                        .finally(() => {
                            this.loadingInner = false
                        })
                }
            } else {
                this.requestErrors = []
            }
        },
        upload(file) {
            return new Promise((resolve, reject) => {
                MediaApiClient.upload(file)
                    .then((media) => {
                        resolve(media)
                    })
                    .catch((reason) => {
                        if (!reason.response || reason.response.status >= 500)
                            Notification.requestError(reason)

                        if (reason.response && reason.response.data)
                            this.requestErrors = reason.response.data.errors
                        else this.requestErrors = []

                        reject(reason)
                    })
            })
        },
        clear() {
            this.$emit('input', null)
            this.$emit('clear')
            this.file = this.multiple ? [] : null
            this.requestErrors = []
        },
        dragover() {
            if (this.showDrag && !this.disabled) this.dragged = true
        },
        dragenter() {
            if (this.showDrag && !this.disabled) this.dragged = true
        },
        dragleave() {
            if (this.showDrag && !this.disabled) this.dragged = false
        },
        onClickOutside() {
            if (!this.fileIsEmpty && !this.$slots.upload && !this.hideActions) {
                this.requestErrors = [this.$t('labels.please_upload')]
                this.uploadElevation = 10
                setTimeout(() => {
                    this.uploadElevation = 2
                }, 500)
            }
        },
    },
}
</script>

<template lang="pug">
    v-card.upload-input(
        v-click-outside="onClickOutside"
        :class="{ 'c-pointer': showDrag && !disabled, 'bg-transparent': !card && !dragged }"
        :loading="card && (loadingInner || loading)"
        @drop.prevent='onDrop($event)'
        @dragover.prevent='dragover'
        @dragenter.prevent='dragenter'
        @dragleave.prevent='dragleave'
        :tile="!card" flat)
        v-card-text.drag-n-drop.bg-g-e8.mb-3(v-if="showDrag" @click="openUploaderDialog" :class="{'pa-0': !card}" :style="{height: `${dragHeight}px`}")
            v-progress-circular.progress(v-if="loadingInner || loading" indeterminate color="primary")
            .d-flex.flex-column.align-center(v-else)
                v-icon(:class="{'mb-4': dragged}" size='60')
                    | cloud_upload
                p.text-center.mb-1(v-html="$tc('texts.drag_n_drop', multiple ? 2 : 1)")
                v-btn(color="primary") {{$t('actions.browse_files')}}
        v-expand-transition(v-if="onlyDrag")
            span.error--text.drag-n-drop-error(v-if="errorCollection && errorCollection.length") {{errorCollection[0]}}

        v-card-actions(:class="{'pa-0': !card}")
            v-file-input(
                v-model="file"
                ref="upload"
                accept="image/png, image/jpeg, image/jpg, image/gif"
                :error-messages="errorCollection"
                :disabled="disabled"
                :multiple="multiple"
                :class="{'d-none': !showInput}"
                @change="submit()"
                :append-icon="file ? 'close' : undefined"
                :clearable="false"
                :label="labelText"
                @click:append="clear"
                :small-chips="multiple"
                :loading="!card && (loadingInner || loading)")
                v-btn(v-if="!$slots.upload" slot="append-outer" color="primary" :elevation="uploadElevation" :disabled="!file" @click.stop='uploadImages' small icon)
                    v-icon file_upload
                slot(name="upload" slot="append-outer")
            .d-flex.justify-end.w-100(v-if="onlyDrag && !hideActions")
                slot(name="buttons")
                v-btn.mr-2(v-if="!$slots.cancel" color="g-e8" @click="clear" :disabled="fileIsEmpty") {{$t('actions.cancel')}}
                slot(name="cancel")
                v-btn(v-if="!$slots.upload" color="g-e8" @click="uploadImages" :disabled="fileIsEmpty" :elevation="uploadElevation") {{$t('actions.upload_photo')}}
                slot(name="upload")

</template>
<style lang="scss">
.upload-input {
    position: relative;

    .v-image__image--preload {
        z-index: 1;
        filter: blur(0px);
        background-size: 15%;
    }

    .v-image__image:not(.v-image__image--preload) {
        z-index: 1;
    }

    .drag-n-drop-error {
        position: absolute;
    }

    .drag-n-drop {
        display: flex;
        align-items: center;
        justify-content: center;

        &.merged {
            position: absolute;
            z-index: 2;
        }
    }
    .v-carousel {
        .v-carousel__controls {
            height: 10px;
            .v-item-group {
                .v-carousel__controls__item {
                    opacity: 0.5;
                    transform: scale(0.5);
                }
            }
        }
    }
}
</style>
