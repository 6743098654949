<template>
    <div>
        <promo-banner-form></promo-banner-form>
    </div>
</template>

<script>
import PromoBannerForm from '@/components/pages/promoBanner/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        PromoBannerForm,
    },
    mixins: [dirtyForm],
}
</script>
